import { createSlice } from "@reduxjs/toolkit";
import NetworkOps from "../../services/NetworkOps";
import Urls from "../../services/Urls";
const profileSlice = createSlice({
  name: "profile",
  initialState: {
    userData: {},
    billData: {},
    isLoading: false,
  },
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },

    userData(state, action) {
      if (action.payload?.success) {
        const resData = action.payload?.data;

        let displayName = resData?.displayName;
        let avatar = resData?.avatar;
        let email = resData?.email;
        let currentBalance = resData?.rewardPoints?.currentBalance;
        let allTimeBalance = resData?.rewardPoints?.allTimeBalance;
        let imgBG = resData?.rewardPoints?.imgBG;
        let imgBadge = resData?.rewardPoints?.imgBadge;
        let pointsExpiryDate = resData?.rewardPoints?.pointsExpiryDate;
        let tier = resData?.rewardPoints?.tier;
        let tierExpiryDate = resData?.rewardPoints?.tierExpiryDate;
        let trailingBalance = resData?.rewardPoints?.trailingBalance;
        let affiliate = resData?.affiliate;
        // let perxBanner = resData?.perx?.banner || "";
        // let perx_microsite = resData?.perx?.perx_microsite || "";
        // let perxPopup_banner = resData?.perx?.popup_banner || "";
        let popup = resData?.popup;
        let games = resData?.games;
        let rewards = resData?.rewards;
        let plans = resData?.plans;

        state.userData = {
          displayName,
          avatar,
          email,
          currentBalance,
          allTimeBalance,
          imgBG,
          imgBadge,
          pointsExpiryDate,
          tier,
          tierExpiryDate,
          trailingBalance,
          affiliate,
          // perxBanner,
          // perx_microsite,
          // perxPopup_banner,
          popup,
          games,
          rewards,
          plans,
        };
      } else {
        state.userData = {};
      }
    },
    billData(state, action) {
      if (action.payload?.success) {
        const resData = action.payload?.data;
        let actualCurrentBalance = resData?.response?.actualCurrentBalance;
        let billDueDate = resData?.response?.billDetails?.billDueDate;
        state.billData = {
          actualCurrentBalance,
          billDueDate,
        };
      } else {
        state.billData = { actualCurrentBalance: 0, billDueDate: "NA" };
      }
    },
  },
});

export const getDashboardData = () => {
  return async (dispatch) => {
    dispatch(profileActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.getDashboardData);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(profileActions.endLoading());
      dispatch(profileActions.userData({ success: true, data: res }));
    } else {
      dispatch(profileActions.endLoading());
      dispatch(profileActions.userData({ success: false }));
    }
  };
};

export const getBillDetails = () => {
  return async (dispatch) => {
    dispatch(profileActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.getBillDetails);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(profileActions.endLoading());
      dispatch(profileActions.billData({ success: true, data: res }));
    } else {
      dispatch(profileActions.endLoading());
      dispatch(profileActions.billData({ success: false }));
    }
  };
};

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;
