import { createSlice } from "@reduxjs/toolkit";

const lifestyleSlice = createSlice({
  name: "lifestyle",
  initialState: {
    lifestylePanel: "0",
    data: {},
  },
  reducers: {
    lifestylePanel(state, action) {
      state.lifestylePanel = action.payload;
    },
    lifestyleData(state, action) {
      state.data = action.payload;
    },
  },
});

export const lifestylesActions = lifestyleSlice.actions;
export default lifestyleSlice;
