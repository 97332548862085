import { createSlice } from "@reduxjs/toolkit";
import NetworkOps from "../../services/NetworkOps";
import Urls from "../../services/Urls";
import { affiliateActions } from "./affiliateSlice";
const affiliateTopUpSlice = createSlice({
  name: "affiliateTopUp",
  initialState: {
    affiliateTopUpData: [],
    affiliateTopUp: {},
    affiliateMobileQuery: {},
  },
  reducers: {
    getAffiliateTopup(state, action) {
      state.affiliateTopUp = action.payload;
    },
    affiliateTopUpData(state, action) {
      state.affiliateTopUpData = action.payload;
    },
    affiliatePayment(state, action) {
      state.affiliatePaymentData = action.payload;
    },
    affiliateMobileQueryData(state, action) {
      state.affiliateMobileQuery = action.payload;
    },
  },
});

export const getAffiliateTopUpData = (data) => {
  return async (dispatch) => {
    dispatch(affiliateActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.affiliateTopUp, data);
    if (res.status_code === 200) {
      const { topups } = res;
      dispatch(affiliateActions.endLoading());
      dispatch(affiliateTopUpActions.affiliateTopUpData(topups));
    } else {
      dispatch(affiliateActions.endLoading());
      dispatch(affiliateTopUpActions.affiliateTopUpData([]));
    }
  };
};

export const getMobileQueryData = (data) => {
  return async (dispatch) => {
    dispatch(affiliateActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.getQueryMobile, data);
    if (res.status_code === 200) {
      dispatch(affiliateActions.endLoading());
      dispatch(affiliateTopUpActions.affiliateMobileQueryData(res.account));
    } else {
      dispatch(affiliateActions.endLoading());
    }
  };
};

export const affiliateTopUpActions = affiliateTopUpSlice.actions;
export default affiliateTopUpSlice.reducer;
