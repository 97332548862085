import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    linkNewNumberPopup: false,
  },
  reducers: {
    linkNewNumberOpen(state) {
      state.linkNewNumberPopup = true;
    },
    linkNewNumberClose(state) {
      state.linkNewNumberPopup = false;
    },
  },
});

export const homeActions = homeSlice.actions;
export default homeSlice.reducer;
