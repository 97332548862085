import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import translationEN from "./assets/locales/en/translation.json";
import translationBM from "./assets/locales/bm/translation.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      bm: {
        translation: translationBM,
      },
    },
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "localStorage",
        "sessionStorage",
        "path",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    // backend : {
    //   loadPath: '/assets/locales/{{lng}}/translation.json',
    // },
    react: {
      useSuspense: false,
    },
  });
export default i18n;
