import { createSlice } from "@reduxjs/toolkit";

const billSlice = createSlice({
  name: "bill",
  initialState: {
    billPanel: "0",
    data: {},
  },
  reducers: {
    switchBillPanel(state, action) {
      state.billPanel = action.payload;
    },
    billData(state, action) {
      state.data = action.payload;
    },
  },
});

export const billActions = billSlice.actions;
export default billSlice;
