import { createSlice } from "@reduxjs/toolkit";
import NetworkOps from "../../services/NetworkOps";
import Urls from "../../services/Urls";

const manageNumberSlice = createSlice({
  name: "manageNumber",
  initialState: {
    numbersData: [],
    isLoading: false,
  },
  reducers: {
    numbersData(state, action) {
      if (action.payload?.success) {
        let plans = action.payload?.data?.plans;
        let data = {
          prepaidPlans: plans?.Prepaid || [],
          postpaidPlans: plans?.Postpaid || [],
          fbbPlans: plans?.FBB || [],
        };
        const numbers = [
          ...data.prepaidPlans,
          ...data.postpaidPlans,
          ...data?.fbbPlans,
        ];
        state.numbersData = numbers;
      } else {
        state.numbersData = [];
      }
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
  },
});

export const getNumbersData = () => {
  return async (dispatch) => {
    dispatch(manageNumberActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.getAddonListplans);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(manageNumberActions.numbersData({ success: true, data: res }));
      dispatch(manageNumberActions.endLoading());
    } else {
      dispatch(manageNumberActions.numbersData({ success: false }));
      dispatch(manageNumberActions.endLoading());
    }
  };
};

export const supplementaryPromote = (data) => {
  return async (dispatch) => {
    let res = await NetworkOps.postToJson(Urls.supplementaryPromote, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      return { success: true, data: res };
    }
    return { success: false };
  };
};

export const supplementaryRemove = (data) => {
  return async (dispatch) => {
    let res = await NetworkOps.postToJson(Urls.supplementaryRemove, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      return { success: true, data: res };
    }
    return { success: false };
  };
};

export const labelUpdate = (data) => {
  return async (dispatch) => {
    let res = await NetworkOps.postToJson(Urls.labelUpdate, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      return { success: true, data: res };
    }
    return { success: false };
  };
};

export const manageNumberActions = manageNumberSlice.actions;
export default manageNumberSlice;
