import React from "react";
import { Route, Redirect } from "react-router-dom";
import SessionService from "./services/SessionService";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const access_token = SessionService.get("userInfo")?.access_token;
  // let expires_in = SessionService.get("userInfo")?.expires_in - 30;

  // refresh token
  let seconds = new Date().getTime() / 1000;
  let timeStamp = SessionService.get("timeStamp");
  let apiCall = Math.floor(timeStamp - seconds);
  const [apiCallTime, setApiCallTime] = React.useState(apiCall);
  React.useEffect(() => {
    const interval = setInterval(() => {
      const refreshT = async () => {
        let refreshToken = SessionService.get("userInfo")?.refresh_token;
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic U29OS21GcnZ4VnVoY3ZrWjBpWFloNW1Xc3A0WEY1V2cxOWpSUW55ODpzTWRLNG1VYlFKMFNPMEJrT1VvMGNHb0RUSHFIWm9BOHhSejN0dUtZ"
        );
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        let response = await fetch(
          "https://api.progresif.com:4747/oauth/token",
          requestOptions
        );
        if (response.status === 200) {
          let data = await response.json();
          SessionService.set("userInfo", data);
          let expires_in = SessionService.get("userInfo")?.expires_in;
          setApiCallTime(expires_in - 30);
          if (expires_in) {
            var seconds = new Date().getTime() / 1000;
            let timeStamp = seconds + expires_in;
            SessionService.set("timeStamp", timeStamp);
          }
        } else {
          localStorage.clear();
          window.location.href = "/login";
        }
      };
      refreshT();
    }, apiCallTime * 1000);
    return () => clearInterval(interval);
  }, [apiCallTime]);

  // RefToken localstorage timer start

  // let timeout = expires_in - 180;
  // const initialTimer = localStorage.getItem("timer") ?? timeout;
  // const timeoutId = React.useRef(null);
  // const [timer, setTimer] = React.useState(initialTimer);

  // const countTimer = React.useCallback(() => {
  //   if (timer <= 0) {
  //     refreshT();
  //     // localStorage.clear("timer");
  //   } else {
  //     setTimer(timer - 1);
  //     localStorage.setItem("timer", timer);
  //   }
  // }, [timer]);
  // const refreshT = async () => {
  //   let refreshToken = SessionService.get("userInfo")?.refresh_token;
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Authorization",
  //     "Basic U29OS21GcnZ4VnVoY3ZrWjBpWFloNW1Xc3A0WEY1V2cxOWpSUW55ODpzTWRLNG1VYlFKMFNPMEJrT1VvMGNHb0RUSHFIWm9BOHhSejN0dUtZ"
  //   );
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     grant_type: "refresh_token",
  //     refresh_token: refreshToken,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };
  //   let response = await fetch(
  //     "https://api.progresif.com:4747/oauth/token",
  //     requestOptions
  //   );
  //   if (response.status === 200) {
  //     let data = await response.json();
  //     SessionService.set("userInfo", data);
  //     let expires_in = SessionService.get("userInfo")?.expires_in;
  //     if (expires_in) {
  //       var seconds = new Date().getTime() / 1000;
  //       let timeStamp = seconds + expires_in;
  //       SessionService.set("timeStamp", timeStamp);
  //     }
  //     setTimer(timeout - 1);
  //     localStorage.setItem("timer", timer);
  //   }
  // };

  // React.useEffect(() => {
  //   timeoutId.current = window.setTimeout(countTimer, 1000);
  //   // cleanup function
  //   return () => window.clearTimeout(timeoutId.current);
  // }, [timer, countTimer]);

  // RefToken localstorage timer end

  if (!Component) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // return access_token ? <Component {...props} /> : <Redirect to="/" />;
        return access_token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }}
    />
  );
};

export default ProtectedRoute;
