import { createSlice } from "@reduxjs/toolkit";

const planSlice = createSlice({
  name: "plan",
  initialState: {
    planDetails: {},
    selectedYoAddon: 1,
    addonValidity: 1,
  },
  reducers: {
    getPlanData(state, action) {
      state.planDetails = action.payload;
    },
    setSelectedYoAddon(state, action) {
      state.selectedYoAddon = action.payload;
    },
    setAddonValidity(state, action) {
      state.addonValidity = action.payload;
    },
  },
});

export const planActions = planSlice.actions;
export default planSlice;
