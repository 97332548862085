import { get } from "lodash";
import { urlFor } from "./Urls.js";
import SessionService from "./SessionService";
import { toast } from "react-toastify";
import SessionStorage from "./SessionStorage.js";
export class NetworkOps {
  async getRequest(type, options) {
    const headerOverrides = get(options, "headerOverrides", {});
    let request = {
      method: type,
      body: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic U29OS21GcnZ4VnVoY3ZrWjBpWFloNW1Xc3A0WEY1V2cxOWpSUW55ODpzTWRLNG1VYlFKMFNPMEJrT1VvMGNHb0RUSHFIWm9BOHhSejN0dUtZ`,
        ...headerOverrides,
      },
    };

    const access_token =
      SessionService.get("userInfo")?.access_token ||
      SessionStorage.get("userInfo")?.access_token;
    if (access_token) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${access_token}`,
      };
    }
    return request;
  }

  async getRequest2(type, options) {
    const headerOverrides = get(options, "headerOverrides", {});
    let request = {
      method: type,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic U29OS21GcnZ4VnVoY3ZrWjBpWFloNW1Xc3A0WEY1V2cxOWpSUW55ODpzTWRLNG1VYlFKMFNPMEJrT1VvMGNHb0RUSHFIWm9BOHhSejN0dUtZ`,
        ...headerOverrides,
      },
    };

    const access_token =
      SessionService.get("userInfo")?.access_token ||
      SessionStorage.get("userInfo")?.access_token;
    if (access_token) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${access_token}`,
      };
    }
    return request;
  }

  async wrapperWithOptions(url, request) {
    try {
      const response = await fetch(url, request);
      if (!response.ok) {
        if (response.status === 401) {
          if (this.auth.authToken) {
            alert("Token Expired");
          }
        } else if (response.status === 500) {
          console.log("Got 500", response);
          const err = await response.json();
          if (err?.message) {
            toast.error(err?.message);
          } else {
            toast.error("Internal Server Error");
          }
        } else if (response.status === 400) {
          SessionService.clear();
          window.location.href = "/login";
        } else if (response.status === 406) {
          if (
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/user/billing/paybycard" ||
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/user/billing/paybytoken" ||
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/prepaid/topup/orderbycard" ||
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/prepaid/topup/orderbytoken" ||
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/v2/addon/mobile/quickaddon" ||
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/v2/addon/fbb/quickaddon" ||
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/v2/addon/fbb/list" ||
            response?.url ===
              "https://api.progresif.com:4747/oauth/care/v2/addon/mobile/list"
          ) {
            const err = await response.json();
            return err;
          }
          console.log("Got 406", response);
          const err = await response.json();
          if (err.error_description?.en) {
            toast.error(err.error_description.en);
          } else {
            toast.error(err.error_description);
          }
        } else if (response.status === 405) {
          console.log("Got 405", response);
          const err = await response.json();
          toast.error(err.error_description);
        }
        const err = await response.json();
        const newError = new Error(get(err, "title"));
        newError.name = response.status;
        throw newError;
      } else {
        let res = await response.text();
        let ress;
        if (response.status === 200 && typeof res === "string") {
          ress = JSON.parse(res);
          ress.status_code = 200;
        }
        try {
          return JSON.parse(ress);
        } catch {
          return ress;
        }
      }
    } catch (error) {
      return error;
    }
  }

  postToJson = async (service, data) => {
    try {
      const JSONData = JSON.stringify(data);
      return this.postRaw(service, JSONData);
    } catch (err) {
      throw err;
    }
  };

  postRaw = async (service, data, options = "") => {
    try {
      const request = await this.getRequest("POST", options);
      request.body = data;
      return this.wrapperWithOptions(urlFor(service), request);
    } catch (err) {
      throw err;
    }
  };

  putToJson = async (service, data) => {
    try {
      const request = await this.getRequest("PUT");
      request.body = JSON.stringify(data);
      return this.wrapperWithOptions(urlFor(service), request);
    } catch (err) {
      throw err;
    }
  };

  get = async (service) => {
    try {
      const request = await this.getRequest2("GET");
      return this.wrapperWithOptions(urlFor(service), request);
    } catch (err) {
      throw err;
    }
  };

  delete = async (service) => {
    try {
      const request = await this.getRequest("DELETE");
      return this.wrapperWithOptions(urlFor(service), request);
    } catch (err) {
      throw err;
    }
  };

  getRaw = async (service) => {
    try {
      const request = await this.getRequest("GET");
      return this.wrapperWithOptions(service, request);
    } catch (err) {
      throw err;
    }
  };
}

export default new NetworkOps();
