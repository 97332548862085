import { configureStore, combineReducers } from "@reduxjs/toolkit";
import planSlice from "../slices/planSlice";
import switchPanelSlice from "../slices/switchPanelSlice";
import perksSlice from "../slices/perksSlice";
import loaderSlice from "../slices/loaderSlice";
import billSlice from "../slices/billSlice";
import lifestyleSlice from "../slices/lifestyleSlice";
import settingSlice from "../slices/settingSlice";
import manageNumberSlice from "../slices/manageNumberSlice";
import addonReducer from "../slices/addonSlice";
import affiliateReducer from "../slices/affiliateSlice";
import affiliateTopUpReducer from "../slices/affiliateTopupSlice";
import sendCreditSliceReducer from "../slices/sendCreditSlice";
import homeReducer from "../slices/homeSlice";
import profileReducer from "../slices/profileSlice";
import userSettingReducer from "../slices/userSettingSlice";
import shareSlice from "../slices/shareSlice";

const combinedReducer = combineReducers({
  share: shareSlice.reducer,
  plan: planSlice.reducer,
  switchPanel: switchPanelSlice.reducer,
  perks: perksSlice.reducer,
  loader: loaderSlice.reducer,
  bill: billSlice.reducer,
  lifestyle: lifestyleSlice.reducer,
  setting: settingSlice.reducer,
  manageNumber: manageNumberSlice.reducer,
  addon: addonReducer,
  affiliate: affiliateReducer,
  affiliateTopUp: affiliateTopUpReducer,
  affiliateSendCredit: sendCreditSliceReducer,
  home: homeReducer,
  profile: profileReducer,
  userSetting: userSettingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
