import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import NetworkOps from "../../services/NetworkOps";
import Urls from "../../services/Urls";

const addonSlice = createSlice({
  name: "addon",
  initialState: {
    isLoading: false,
    addonData: [],
    fbbData: [],
    mobileData: [],
    addOnName: {},
    addonDataAll: [],
  },
  reducers: {
    addonData(state, { payload }) {
      if (payload?.success) {
        let plans = payload?.data?.plans;
        let data = {
          prepaidPlans: plans?.Prepaid || [],
          postpaidPlans: plans?.Postpaid || [],
          fBB: plans?.FBB || [],
        };
        const addonList = [
          ...data.prepaidPlans,
          ...data.postpaidPlans,
          ...data.fBB,
        ];
        let mobilePlansArr = [...data.prepaidPlans, ...data.postpaidPlans];
        state.addonData = addonList;
        state.fbbData = data?.fBB;
        state.mobileData = mobilePlansArr;
      } else {
        state.addonData = [];
        state.fbbData = [];
        state.mobileData = [];
      }
    },
    getAddOnName(state, { payload }) {
      state.addOnName = payload;
    },
    setAddOnData(state, { payload }) {
      if (payload?.success) {
        state.addonDataAll = payload?.data?.addons;
      } else {
        state.addonDataAll = [];
        toast.info("No add ons present");
      }
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
  },
});

export const getAddonData = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    let res = await NetworkOps.postToJson(Urls.getAddonListplans);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      dispatch(endLoading());
      delete res.status_code;
      dispatch(addonData({ success: true, data: res }));
    } else {
      dispatch(endLoading());
      dispatch(addonData({ success: false }));
    }
  };
};

export const getAddOnMobile = (data) => {
  return async (dispatch) => {
    dispatch(startLoading());
    let res = await NetworkOps.postToJson(Urls.getAddOnMobile, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(setAddOnData({ success: true, data: res }));
      dispatch(endLoading());
    } else {
      dispatch(setAddOnData({ success: false }));
      dispatch(endLoading());
    }
  };
};

export const getAddOnBroadband = (data) => {
  return async (dispatch) => {
    dispatch(startLoading());
    let res = await NetworkOps.postToJson(Urls.getAddOnBroadband, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(setAddOnData({ success: true, data: res }));
      dispatch(endLoading());
    } else {
      dispatch(setAddOnData({ success: false }));
      dispatch(endLoading());
    }
  };
};

export const addOnOrderFbb = (data) => {
  return async (dispatch) => {
    dispatch(startLoading());
    let res = await NetworkOps.postToJson(Urls.addOnOrderFbb, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      dispatch(endLoading());
      delete res.status_code;
      return { success: true, data: res };
    } else {
      dispatch(endLoading());
      return { success: false };
    }
  };
};

export const addOnOrderMobile = (data) => {
  return async (dispatch) => {
    dispatch(startLoading());
    let res = await NetworkOps.postToJson(Urls.addOnOrderMobile, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      dispatch(endLoading());
      delete res.status_code;
      return { success: true, data: res };
    } else {
      dispatch(endLoading());
      return { success: false };
    }
  };
};

export const {
  addonData,
  getAddOnName,
  setAddOnData,
  startLoading,
  endLoading,
} = addonSlice.actions;
export default addonSlice.reducer;
