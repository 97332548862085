import React from "react";
import "./loader.css";
import loader from "../../../assets/loader/CARE_ICON_LOADING_.gif";
const Loader = () => {
  return (
    <div className="ins-prf-img-grd">
      <div className="loader-for-chat-div">
        <div className="loader-for-chat">
          <img src={loader} style={{ width: "200px" }} alt="loader" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
