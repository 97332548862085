import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import ErrorBoundary from "./components/common/ErrorBoundary";
import { Provider } from "react-redux";
import store from "./redux/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18nextConfig";

toast.configure();

ReactDOM.render(
  <Provider store={store}>
    {/* <ErrorBoundary> */}
    <App />
    {/* </ErrorBoundary> */}
  </Provider>,
  document.getElementById("root")
);
