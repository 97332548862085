import React, { useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import MobileScreen from "./pages/MobileScreen/MobileScreen";
import SessionService from "./services/SessionService";
import SessionStorage from "./services/SessionStorage";

var isMobile = false;
function App(props) {
  //RefreshTOken
  const [expToken, setExpToken] = React.useState(false);
  useEffect(() => {
    const access_token = SessionService.get("userInfo")?.access_token;
    if (access_token) {
      let seconds = new Date().getTime() / 1000;
      let timeStamp = SessionService.get("timeStamp");
      if (seconds > timeStamp) {
        const refToken = async () => {
          let refreshToken = SessionService.get("userInfo")?.refresh_token;
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            "Basic U29OS21GcnZ4VnVoY3ZrWjBpWFloNW1Xc3A0WEY1V2cxOWpSUW55ODpzTWRLNG1VYlFKMFNPMEJrT1VvMGNHb0RUSHFIWm9BOHhSejN0dUtZ"
          );
          myHeaders.append("Content-Type", "application/json");
          var raw = JSON.stringify({
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          });
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          let response = await fetch(
            "https://api.progresif.com:4747/oauth/token",
            requestOptions
          );
          if (response.status === 200) {
            let data = await response.text();
            localStorage.removeItem("userInfo");
            localStorage.setItem("userInfo", data);
            setExpToken(true);
            let expires_in = JSON.parse(
              localStorage.getItem("userInfo")
            )?.expires_in;
            if (expires_in) {
              var seconds = new Date().getTime() / 1000;
              let timeStamp = seconds + expires_in;
              localStorage.setItem("timeStamp", timeStamp);
              SessionStorage.set("perxPopup", true);
            }
          } else {
            localStorage.clear();
            setExpToken(true);
          }
        };
        refToken();
      } else {
        setExpToken(true);
      }
    } else {
      setExpToken(true);
    }
  }, []);
  //

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  if (isMobileDevice) {
    isMobile = true;
  } else {
    isMobile = false;
  }

  return (
    <>
      {isMobile && <MobileScreen />}
      {expToken && <Routes />}
    </>
  );
}
export default App;
