import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: { url: null, title: null, text: null },
  show: false,
};

const shareSlice = createSlice({
  name: "share",
  initialState,
  reducers: {
    setShareURL(state, action) {
      const { url, title, text } = action.payload;
      if (navigator.share) {
        navigator
          .share({ title, url, text })
          .catch((err) => console.error(err));
      } else {
        state.data.url = url;
        state.data.title = title;
        state.data.text = text;
        state.show = true;
      }
    },
    removeShareURL() {
      return initialState;
    },
  },
});

export const shareActions = shareSlice.actions;
export default shareSlice;
