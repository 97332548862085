import React, { useEffect, useState } from "react";
import logo from "../../assets/images/PCLogo.svg";
import Play from "../../assets/images/playstore.svg";
import AppStore from "../../assets/images/appstore.svg";
import { useTranslation } from "react-i18next";

const MobileScreen = () => {
  const { t } = useTranslation();
  const [isAndroid, setIsAndroid] = useState(false);
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  useEffect(() => {
    if (/windows phone/i.test(userAgent)) {
      setIsAndroid(true);
      return "Windows Phone";
    } else if (/android/i.test(userAgent)) {
      setIsAndroid(true);
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsAndroid(false);
      return "iOS";
    } else {
      console.log("Desktop ");
    }
  }, [userAgent]);

  return (
    // <div className="loginContainer">
    <div className="mobileBreakPoin">
      <div className="mblRdrc">
        <img className="img-fluid" src={logo} alt="logo" />
        <p>
        {t("mobileDownload")}
        </p>
        <div className="appBtnsss">
          {isAndroid ? (
            <img
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.progresif.careplus",
                  "_blank"
                );
              }}
              className="img-fluid"
              src={Play}
              alt="playstore"
            />
          ) : (
            <img
              onClick={() => {
                window.open(
                  "https://apps.apple.com/app/id1601634213",
                  "_blank"
                );
              }}
              className="img-fluid"
              src={AppStore}
              alt="appstore"
            />
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default MobileScreen;
