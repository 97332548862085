import { createSlice } from "@reduxjs/toolkit";

const switchPanelSlice = createSlice({
  name: "switchPanel",
  initialState: {
    panel: "0",
    panel2: "0",
    data: null,
    panel2Data: null,
  },
  reducers: {
    switchPanel(state, action) {
      state.panel = action.payload;
    },
    switchLoyaltyPanel(state, action) {
      state.panel2 = action.payload;
      state.panel2Data = null;
    },
    topupData(state, action) {
      state.data = action.payload;
    },
    setLotaltyPanelData(state, action) {
      state.panel2Data = action.payload;
    },
  },
});

export const switchPanelActions = switchPanelSlice.actions;
export default switchPanelSlice;
