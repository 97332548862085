import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import NetworkOps from "../../services/NetworkOps";
import Urls from "../../services/Urls";
import { affiliateActions, getAffiliateInfo } from "./affiliateSlice";
import { getDashboardData } from "./profileSlice";

const sendCreditSlice = createSlice({
  name: "sendcredit",
  initialState: {
    favouritesData: {},
    affiliateSendCreditData: {},
  },
  reducers: {
    favouritesData(state, action) {
      state.favouritesData = action.payload;
    },
    affiliateSendCreditData(state, action) {
      if (action?.payload?.status_code) {
        state.affiliateSendCreditData = action.payload;
      } else {
        state.affiliateSendCreditData = {};
      }
    },
  },
});

export const setFavouritesData = (data) => {
  return async (dispatch) => {
    dispatch(affiliateActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.sendCreditFavourite, data);
    if (res?.status_code === 200) {
      dispatch(affiliateActions.endLoading());
      dispatch(getAffiliateInfo());
      dispatch(sendCreditActions.favouritesData(res));
    } else {
      dispatch(affiliateActions.endLoading());
      
    }
  };
};

export const setAffiliateSendCredit = (data) => {
  return async (dispatch) => {
    dispatch(affiliateActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.affiliateSendCredit, data);
    let res2 = { ...res };
    if (res2?.status_code === 200) {
      delete res.status_code;
      dispatch(affiliateActions.endLoading());
      dispatch(sendCreditActions.affiliateSendCreditData(res2));
      dispatch(getAffiliateInfo());
      dispatch(getDashboardData());
      window.scrollTo(0, 0);
    } else {
      toast.error("Something went wrong");
      dispatch(affiliateActions.endLoading());
    }
  };
};

export const sendCreditActions = sendCreditSlice.actions;
export default sendCreditSlice.reducer;
