import { createSlice } from "@reduxjs/toolkit";
import NetworkOps from "../../services/NetworkOps";
import Urls from "../../services/Urls";
import { getDashboardData } from "./profileSlice";
const affiliateSlice = createSlice({
  name: "affiliate",
  initialState: {
    affiliateData: {},
    registerStatus: "",
    isLoading: false,
    prepaidAvailablePopup: false,
    submittedPopup: false,
    couponChangePopup: false,
    affiliatePanel: null,
    accountInfo: {},
    successReceipt: "",
    successText: null,
  },
  reducers: {
    setSuccessText(state, action) {
      state.successText = action.payload;
    },
    switchAffiliatePanel(state, action) {
      state.affiliatePanel = action.payload;
    },
    successReciptData(state, action) {
      state.successReceipt = action.payload;
    },
    affiliateAccountInfo(state, action) {
      state.accountInfo = action.payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    prepaidAvailablePopupOpen(state) {
      state.prepaidAvailablePopup = true;
    },
    prepaidAvailablePopupClose(state) {
      state.prepaidAvailablePopup = false;
    },
    submittedPopupOpen(state) {
      state.submittedPopup = true;
    },
    submittedPopupClose(state) {
      state.submittedPopup = false;
    },
    couponPopupOpen(state) {
      state.couponChangePopup = true;
    },
    couponPopupClose(state) {
      state.couponChangePopup = false;
    },
    affiliateData(state, action) {
      if (action.payload?.success) {
        let affiliateID = action.payload?.data?.affiliateID;
        let overviewImage = action.payload?.data?.dashboard?.overviewImage;
        let informationImage =
          action.payload?.data?.dashboard?.informationImage;
        let informationRecharge =
          action.payload?.data?.dashboard?.informationRecharge;
        let informationRefer =
          action.payload?.data?.dashboard?.informationRefer;

        let prepaidLinked = action.payload?.data?.prepaidCheck?.prepaidLinked;
        let prepaidImage = action.payload?.data?.prepaidCheck?.prepaidImage;
        let prepaidWebstore =
          action.payload?.data?.prepaidCheck?.prepaidWebstore;
        let affiliateStatus = action.payload?.data?.dashboard?.affiliateStatus;
        let couponCode = action.payload?.data?.dashboard?.couponCode;
        let earningsLifetime =
          action.payload?.data?.dashboard?.earningsLifetime || 0;
        let earningsMonthly =
          action.payload?.data?.dashboard?.earningsMonthly || 0;
        let referralsLifetime =
          action.payload?.data?.dashboard?.referralsLifetime || 0;
        let referralsMonthly =
          action.payload?.data?.dashboard?.referralsMonthly || 0;
        let prepaidAccounts =
          action.payload?.data?.prepaidCheck?.accounts || [];
        let recentHistory = action.payload?.data?.recentHistory || [];
        let affiliateHistory = action.payload?.data?.affiliateHistory || [];
        let favourites = action.payload?.data?.favourites || [];
        let recentRecipients = action.payload?.data?.recentRecipients || [];
        let MGMearningsMonthly =
          action.payload?.data?.dashboard?.MGMearningsMonthly || 0;
        let MGMearningsLifetime =
          action.payload?.data?.dashboard?.MGMearningsLifetime || 0;
        let MGMreferralsLifetime =
          action.payload?.data?.dashboard?.MGMreferralsLifetime || 0;
        let MGMreferralsMonthly =
          action.payload?.data?.dashboard?.MGMreferralsMonthly || 0;
        state.affiliateData = {
          affiliateID,
          overviewImage,
          informationImage,
          informationRecharge,
          informationRefer,
          prepaidLinked,
          prepaidImage,
          prepaidWebstore,
          affiliateStatus,
          couponCode,
          earningsLifetime,
          earningsMonthly,
          referralsLifetime,
          referralsMonthly,
          prepaidAccounts,
          recentHistory,
          affiliateHistory,
          favourites,
          recentRecipients,
          MGMearningsMonthly,
          MGMearningsLifetime,
          MGMreferralsLifetime,
          MGMreferralsMonthly,
        };
      } else {
        state.affiliateData = {};
      }
    },
    affiliateRegister(state, action) {
      if (action.payload?.success) {
        let registerStatus = action.payload?.data?.response?.message;
        state.registerStatus = registerStatus;
      } else {
        state.registerStatus = "";
      }
    },
    affiliateChangeCoupon(state, action) {
      if (action.payload?.success) {
        let newCouponCode = action.payload?.data?.newCouponCode;
        state.affiliateData = {
          ...state.affiliateData,
          couponCode: newCouponCode,
        };
      } else {
        // state.registerStatus = "";
      }
    },
  },
});
export const getAffiliateInfo = () => {
  return async (dispatch) => {
    dispatch(affiliateActions.startLoading());
    let res = await NetworkOps.get(Urls.affiliateInfo);

    let res2 = { ...res };
    console.log("res2", res2);
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(affiliateActions.endLoading());
      dispatch(affiliateActions.affiliateData({ success: true, data: res2 }));
    } else {
      dispatch(affiliateActions.endLoading());
      dispatch(affiliateActions.affiliateData({ success: false }));
    }
  };
};
export const registerAffiliate = (data) => {
  return async (dispatch) => {
    dispatch(affiliateActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.registerAffiliate, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(affiliateActions.endLoading());
      dispatch(
        affiliateActions.affiliateRegister({ success: true, data: res })
      );
      dispatch(affiliateActions.prepaidAvailablePopupClose());
      dispatch(affiliateActions.submittedPopupOpen());
    } else {
      dispatch(affiliateActions.endLoading());
      dispatch(affiliateActions.affiliateRegister({ success: false }));
    }
  };
};
export const changeAffiliateCoupon = (data) => {
  return async (dispatch) => {
    dispatch(affiliateActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.changeAffiliateCoupon, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(affiliateActions.endLoading());
      dispatch(
        affiliateActions.affiliateChangeCoupon({ success: true, data: res })
      );
      dispatch(affiliateActions.couponPopupClose());
      dispatch(getDashboardData());
    } else {
      dispatch(affiliateActions.endLoading());
      dispatch(affiliateActions.affiliateChangeCoupon({ success: false }));
    }
  };
};
export const affiliateActions = affiliateSlice.actions;
export default affiliateSlice.reducer;
