import { createSlice } from "@reduxjs/toolkit";

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    settingPanel: "0",
  },
  reducers: {
    settingPanel(state, action) {
      state.settingPanel = action.payload;
    },
  },
});

export const settingActions = settingSlice.actions;
export default settingSlice;
