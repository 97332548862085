import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import NetworkOps from "../../services/NetworkOps";
import SessionService from "../../services/SessionService";
import Urls from "../../services/Urls";

const userSettingSlice = createSlice({
  name: "userSetting",
  initialState: {
    settings: {},
    isLoading: false,
    message: "",
  },
  reducers: {
    userSettingData(state, action) {
      if (action.payload?.success) {
        let settings = action.payload?.data?.settings;
        state.settings = settings;
        SessionService.set("settings", settings);
      } else {
        state.settings = {};
      }
    },
    setUserSettingData(state, action) {
      if (action.payload?.success) {
        state.message = action.payload?.data?.message;
        toast.success(state.message);
      } else {
        state.message = "";
      }
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
  },
});

export const getSettingsData = () => {
  return async (dispatch) => {
    dispatch(userSettingSliceActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.getSettings);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(
        userSettingSliceActions.userSettingData({ success: true, data: res })
      );
      dispatch(userSettingSliceActions.endLoading());
    } else {
      dispatch(userSettingSliceActions.userSettingData({ success: false }));
      dispatch(userSettingSliceActions.endLoading());
    }
  };
};

export const setSettingsData = (data) => {
  return async (dispatch) => {
    dispatch(userSettingSliceActions.startLoading());
    let res = await NetworkOps.postToJson(Urls.setSettings, data);
    let res2 = { ...res };
    if (res2.status_code === 200) {
      delete res.status_code;
      dispatch(
        userSettingSliceActions.setUserSettingData({ success: true, data: res })
      );
      dispatch(userSettingSliceActions.endLoading());
      dispatch(getSettingsData());
    } else {
      dispatch(userSettingSliceActions.setUserSettingData({ success: false }));
      dispatch(userSettingSliceActions.endLoading());
    }
  };
};

export const userSettingSliceActions = userSettingSlice.actions;
export default userSettingSlice.reducer;
