import { createSlice } from "@reduxjs/toolkit";

const perksSlice = createSlice({
  name: "perks",
  initialState: {
    perksData: {},
  },
  reducers: {
    getPerksData(state, action) {
      state.perksData = action.payload;
    },
  },
});

export const perksActions = perksSlice.actions;
export default perksSlice;
