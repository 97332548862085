import React, { Suspense, lazy } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute.jsx";
import Loader from "./components/common/loader/Loader.jsx";
const Login = lazy(() => import("./pages/Login/Login"));
const Register = lazy(() => import("./pages/Register/Register"));
const ForgotPassword = lazy(() => import("./pages/Login/ForgotPassword"));
const LinkMobileRegistration = lazy(() =>
  import("./pages/Register/LinkMobileRegistration")
);
const DashboardHome = lazy(() => import("./pages/Dashboard/DashboardHome"));
const AddOn = lazy(() => import("./pages/Add On/AddOn"));
const LifeStyle = lazy(() => import("./pages/LifeStyle/LifeStyle"));
const Deal = lazy(() => import("./pages/Deal/Deal"));
const Billing = lazy(() => import("./pages/Billing/Billing"));
const Setting = lazy(() => import("./pages/Setting/Setting"));
const Affiliate = lazy(() => import("./pages/Affiliate/Affiliate"));
const SharePopup = lazy(() => import("./components/common/SharePopup"));

const PartnerProgram = lazy(() => import("./pages/partnerProgram"));
const Routes = () => {
  const history = createBrowserHistory();

  return (
    <>
      <Suspense fallback={null}>
        <SharePopup />
      </Suspense>
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <ProtectedRoute path="/dashboard" component={DashboardHome} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route
              path="/linkmobileregistration"
              component={LinkMobileRegistration}
            />
            {/* <ProtectedRoute path="/addon" component={AddOn} /> */}
            <ProtectedRoute path="/billing" component={Billing} />
            <ProtectedRoute path="/lifestyle" component={LifeStyle} />
            <ProtectedRoute path="/deal" component={Deal} />
            <ProtectedRoute path="/setting" component={Setting} />
            <ProtectedRoute path="/re-charge" component={Affiliate} />
            <Redirect from="*" to="/" />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default Routes;
